.nav-tabs {
    border: none;
}

.nav-tabs .nav-item {
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 2.4rem;
    color: #6b7280;
    border: none;
}

.nav-tabs a {
    color: #6b7280;
}

.nav-tabs .nav-link {
    border: none;
    border-bottom: 3px solid #e5e7eb;
    /* border-bottom: 1px solid red; */
    margin-right: 1.5rem;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    border-color: #4f46e5;
    color: #1f2937;
}
