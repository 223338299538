@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap');

html {
    font-size: 62.5%;
}

body {
    margin: 0 !important;
    padding: 0 !important;
    font-family: 'Inter', sans-serif !important;
    box-sizing: border-box;
    background-color: #f3f4f6;
    overflow: hidden;
    height: 100vh;
}

a {
    text-decoration: none !important;
}

p {
    margin-bottom: 0;
}


.progressbar {
    position: relative;
    width: 24rem;
    box-sizing: border-box;
    height: 12rem;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    overflow: hidden;
    --percentage: 0;
}

.innerProgress {
    content: '';
    position: absolute;
    width: 24rem;
    height: 24rem;
    box-sizing: border-box;
    border: 50px solid;
    border-radius: 50%;
    border-color: #f3f4f6 #f3f4f6 #6366f1 #6366f1;
    left: 0;
    top: 0;
    transform: rotate(calc(1deg * (-45 + var(--percentage) * 1.8)));
}

::-webkit-scrollbar {
    width: 0.8rem;
    height: 0.8rem;
}
::-webkit-scrollbar-track {
    background: #e5e7eb;
}
::-webkit-scrollbar-thumb {
    background: #9ca3af;
    border-radius: 100px;
}