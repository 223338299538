.nav-pills {
    border: none;
    background-color: #f1f2f4;
}

.nav-pills .nav-item {
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 2.4rem;
    color: #6b7280;
    border: none;
}

.nav-pills .nav-link {
    border: none;
    margin-top: 3px;
    margin-bottom: 3px;
    padding-left: 26.1rem;
    padding-right: 26.1rem;
}
.nav-pills a {
    color: #6b7280;
}

.nav-pills .nav-item.show .nav-link,
.nav-pills .nav-link.active {
    color: #1f2937;
    background-color: #fff;
    padding-left: 26.1rem;
    padding-right: 26rem;
    border-radius: 8px;
}
